import React, { Component } from "react";

import Layout from "../../../components/layout/index";
import SEO from "../../../components/seo";
import TOUGlobalComponent from "../../../components/legal/tou-global";
import mixpanel from "../../../lib/mixpanel/index";

import "../../../layouts/index.css";

class TOUUs extends Component {
  componentDidMount() {
    mixpanel.track("TERMS_OF_USE_US_OPENED");
  }

  render() {
    return (
      <Layout>
        <SEO title="Terms Of Use" disableGoogleCrawler={true} />
        <div className="main-content lh2">
          <TOUGlobalComponent />
        </div>
      </Layout>
    );
  }
}
export default TOUUs;
