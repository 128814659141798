import React from "react";
import styleVars from "../../styles/style-vars";

const TOUGlobalComponent = (props) => {
  return (
    <div className={`${styleVars.page.xl} mb10`}>
      <h1 className="f2 ">Terms of use</h1>
      <p className="f5" style={{ fontStyle: "italic" }}>
        Last modified: January 17, 2019
      </p>
      <div>
        <h3 className="f3  pt5 pb5 ma0">
          1. Acceptance of Terms &amp; Conditions
        </h3>
        <p className="f5">
          This website,{" "}
          <a href="http://getspotlight.co" target="_blank">
            http://getspotlight.co
          </a>
          , (the “Site”, including all subdomains), is a copyrighted work
          belonging to Spotlight Services and Technologies Incorporated
          (“Spotlight”, “we”, “us” and “our”). These terms of service (the
          “Terms”) govern your access to and use of the services available on
          the Sites (the “Services”). Specific features of this Site may have
          additional rules and terms associated with them, and such additional
          rules and terms will be made available to each user (“you” and “your”,
          including any entity you represent) and are incorporated herein by
          reference.
        </p>
        <p className="f5">
          PLEASE READ THESE TERMS CAREFULLY. THESE TERMS CONSTITUTE A BINDING
          LEGAL AGREEMENT BETWEEN YOU AND SPOTLIGHT SERVICES AND TECHNOLOGIES
          INCORPORATED. By using this Site and the Services you unconditionally
          agree to be bound by these Terms, including all exclusions and
          limitations of liability herein, and warrant that you have full
          authority and capacity, legal and otherwise, to use the Services. You
          may not access or use this Site or the Services if you do not agree to
          any part of these Terms.
        </p>
        <p className="f5">
          You must be at least 18 (eighteen) years of age to use this Site. By
          using this Site, you represent that you are at least 18 (eighteen)
          years of age.
        </p>
      </div>
      <div>
        <h3 className="f3  pt5 pb5 ma0">2. License to use the Website</h3>
        <p className="f5">
          This Site primarily operates as a for-profit business website. The
          purpose of this Site is to provide information about, and access to,
          the Services. Subject to these Terms, you are granted a personal,
          worldwide, non-transferable, non-exclusive, royalty-free, limited
          license to use and access the Site. You must use this license only in
          the manner permitted by these Terms for the sole purpose of using and
          enjoying the benefit of the Services provided by the Site.
        </p>
      </div>
      <div>
        <h3 className="f3  pt5 pb5 ma0">3. Cookies</h3>
        <p className="f5">
          Please refer to our Cookies Policy by clicking{" "}
          <a href="/terms-and-conditions/india/cookie-policy" target="_blank">
            here.
          </a>
        </p>
        <p className="f5">
          You also acknowledge that websites such as Google and other
          third-party vendors to our Site may use DART cookies to provide
          add-ons to our Site based upon visitors visiting our Site and other
          websites. Visitors may decline the use of DART cookies by visiting the
          Google ad and content network Privacy Policy at the following URL:
          <a href="http://www.google.com/privacy_ads.html" target="_blank">
            http://www.google.com/privacy_ads.html
          </a>
        </p>
      </div>
      <div>
        <h3 className="f3  pt5 pb5 ma0">4. Acceptable Use Policy</h3>
        <p className="f5">
          By using this Site, you agree to use the Services in compliance with
          these Terms and all applicable rules and regulations, including the
          local, state, national, and international laws that apply to your
          jurisdiction. We are based in New Jersey, United States of America. It
          may be illegal for certain persons to use the Site in some countries.
          We make no claim that the Site will be legal or accessible outside of
          the United States of America. Subject to these Terms, you may view,
          print, and use our content and Services as permitted on the Site and
          only for your own personal, non-commercial use. You further agree and
          acknowledge that your use of this Site and the Services results in you
          sending electronic interstate transmissions through our computer
          network, and such transmissions may include, but are not limited to,
          any searches, file uploads, posts, instant messages, or emails.
        </p>
        <p className="f5">
          The following uses of the Site are prohibited unless we have intended
          to provide you with access or have provided you express written
          permission to the contrary:
          <ol style={{ listStyleType: "lower-alpha", padding: "20px" }}>
            <li>
              Using the Site in a manner that causes, or may cause, damage to
              the Site or in a way that impairs visitors' abilities to access or
              use the Site and Services, including using the Site in a manner or
              for a purpose that is unlawful or fraudulent;
            </li>
            <li>
              Using the Site in order to copy, store, upload, publish, use,
              transmit, host, or distribute anything consisting of or linked to
              any computer virus, trojan horse, spyware, rootkit, worm,
              keystroke logger, or other malicious computer software or software
              that may destroy, damage, or alter a computer system;
            </li>
            <li>
              Conducting any systematic or automated data collection on or
              related to the Site or its users without their consent, including,
              without limitation, data mining, data extraction, scraping, data
              harvesting, "framing," or article "spinning";
            </li>
            <li>
              Using the Site to collect, harvest, or compile information or data
              regarding other users of the Site without their consent;
            </li>
            <li>
              Using software or automated agents or scripts to generate
              automated searches, requests, or queries on the Site or to mine
              data from the Site, provided, however, that operators of public
              search engines have our revocable permission to copy materials
              from the Site for the sole purpose of and only to the extent
              necessary for creating public search indices, but not caches or
              archives of such materials, according to the parameters in our
              robots.txt file;
            </li>
            <li>
              Transmitting or sending unsolicited communications, commercial or
              otherwise, or conducting any marketing activities, including using
              the Site to send spam, pyramid schemes, investment schemes, or
              chain letters;
            </li>
            <li>
              Republishing (including on another website), duplicating, copying,
              redistributing, selling, leasing, transferring, hosting, renting,
              or licensing any part of the Site or otherwise commercially
              exploiting content, whether in whole or in part, on the Site;
            </li>
            <li>
              Editing, modifying, making derivative works of, reverse
              engineering, or reverse compiling any information, content,
              systems, or Services on the Site;
            </li>
            <li>
              Accessing the Site in order to create a similar or competitive
              business, product, service, or website;
            </li>
            <li>
              Collecting, transmitting, distributing, uploading, or displaying
              any content or otherwise using the Site in a manner that (i)
              violates the rights of any third party, including any intellectual
              property rights or rights to privacy, (ii) is unlawful,
              fraudulent, tortious, threatening, vulgar, defamatory or libelous,
              pornographic, obscene, patently offensive, racist, or promotes
              physical harm or injury, including without limitation any
              transmissions constituting or encouraging conduct that would
              constitute a criminal offense, give rise to civil liability, or
              otherwise violate any local, state, national or foreign law, (iii)
              causes or may cause harm to minors in any way, or (iv)
              impersonates another individual or organization or otherwise
              misrepresents affiliation with another person or entity without
              permission;
            </li>
            <li>
              Interfering with, disrupting, or overburdening servers or networks
              connected to the Site;
            </li>
            <li>
              Gaining or attempting to gain unauthorized access by any means to
              any part of the Site or to computers or networks connected to the
              Site; and
            </li>
            <li>
              Harassing, stalking, harming, or otherwise interfering with or
              negatively affecting another user's normal use and enjoyment of
              the Site.
            </li>
          </ol>
        </p>
      </div>
      <div>
        <h3 className="f3  pt5 pb5 ma0">
          5. Enforcement of Acceptable Use Policy
        </h3>
        <p className="f5">
          We reserve the right to review and investigate your use of the Site
          and to take any appropriate action against you that we determine is
          necessary in our sole discretion should you violate these Terms or
          otherwise create liability, loss, or damage for us, our Site visitors,
          or a third party. Such action may include, but is not limited to,
          restricting your account privileges or terminating your account,
          initiating proceedings to recover any losses and reporting you to law
          enforcement authorities.
        </p>
        <p className="f5">
          You acknowledge and agree that Spotlight has the right, but not an
          obligation, to monitor the Site electronically from time to time and
          to disclose any information as necessary or appropriate to satisfy any
          law, regulation or other governmental request, to operate the Site
          properly, or to protect itself or its customers. Spotlight will not
          intentionally monitor or disclose any private electronic-mail message
          unless required by law. Spotlight reserves the right to refuse to post
          or to remove any information or materials, in whole or in part, that,
          in its sole discretion, are unacceptable, undesirable, inappropriate
          or in violation of these Terms.
        </p>
      </div>
      <div>
        <h3 className="f3  pt5 pb5 ma0">6. International use and compliance</h3>
        <p className="f5">
          Due to the global nature of the internet, you understand and agree
          that it is your responsibility to ensure that your use of the Site
          complies with all local, international, and other laws that may apply.
          In addition, United States import and export control laws and the
          import regulations of other countries may apply to the use of the
          Site. You agree not to export, upload, post, or transfer, directly or
          indirectly, any software, technical data, or technology acquired
          through us, the Site, or the Services in violation of such export or
          import laws, including, but not limited to, the United States Export
          Administration Regulations (EAR) and the various United States
          sanctions programs.
        </p>
      </div>
      <div>
        <h3 className="f3  pt5 pb5 ma0">7. Minors</h3>
        <p className="f5">
          We do not knowingly collect personal information or data from visitors
          under 18 years of age. You may not use this site if you are under the
          age of 18 (eighteen).
        </p>
      </div>
      <div>
        <h3 className="f3  pt5 pb5 ma0">8. User Accounts</h3>
        <p className="f5">
          Visitors to the Site are permitted to create user accounts or
          profiles. Upon registering for our Services, you are considered a
          "Member" of the Site. You represent that all information you submit
          when creating an account is true and accurate, and you promise to
          update and maintain the accuracy of this information at all times.
          Each Member is the sole authorized user of his or her account. Members
          are responsible for keeping their passwords and account access
          information confidential. Therefore, you should take measures to
          restrict access to your account and to any devices from which you
          access your account. As a Member, you are responsible for all
          activities that occur under your account, and you acknowledge that
          Spotlight is not responsible for unauthorized access to your account
          that results from theft or misappropriation of your account or
          password. Members are prohibited from assigning or otherwise
          transferring their accounts and passwords to others. You must notify
          us immediately if you know of or suspect that an unauthorized use or
          other breach of security of your account or the Site has occurred. We
          may not be held liable for any loss or damage that may arise from your
          failure to maintain the security of your account. You may delete your
          account at any time and for any reason by following the instructions
          on the Site. We retain the right to suspend or terminate your account
          at any time and for any reason, as further detailed below.
        </p>
      </div>
      <div>
        <h3 className="f3  pt5 pb5 ma0">9. Modifications of site</h3>
        <p className="f5">
          We reserve the right to modify, add to, suspend, or terminate all or
          part of the Site or Services at any time with or without providing
          prior notice to you. This includes the right to create limits on our
          visitors' use of Services and data storage. You agree that we are not
          liable for using or enforcing the rights stated in this paragraph.
          Unless we indicate otherwise, any future modifications to the Site are
          subject to these Terms.
        </p>
      </div>
      <div>
        <h3 className="f3  pt5 pb5 ma0">10. Support or Maintenance</h3>
        <p className="f5">
          Although we may choose to provide customer support or website
          maintenance, you acknowledge and agree that we are under no obligation
          to do so, unless provided for under a specific written agreement to
          that effect.
        </p>
      </div>
      <div>
        <h3 className="f3  pt5 pb5 ma0">11. Restricted Access</h3>
        <p className="f5">
          Some parts of the Site may be restricted to certain visitors. If you
          have permission to access restricted parts of the Site, you agree to
          not share your access information and password with third parties. We
          may change the restricted parts of the Site from time to time. If you
          do not have access to restricted parts of the Site, you agree not to
          use another user's account to gain such access or otherwise attempt to
          gain improper access to the restricted parts of the Site.
        </p>
      </div>
      <div>
        <h3 className="f3  pt5 pb5 ma0">12. Privacy</h3>
        <p className="f5">
          We manage the collection, use, and security of your personal
          information according to our{" "}
          <a href="/terms-and-conditions/global/cookie-policy" target="_blank">
            Privacy Policy
          </a>
          , incorporated herein by reference. By using this Site, you consent to
          our collection and use of your personal information as set forth in
          the Privacy Policy.
        </p>
        <p className="f5">
          At times we may need to send you communications related to the Site or
          the Services. Such communications are considered part of the Services,
          and you may not be able to opt out of receiving them.
        </p>
        <p className="f5">
          We reserve the right to access and disclose the information you submit
          to the Site if required to do so by law or if we have a reasonable,
          good-faith belief that doing so is necessary for (i) responding to
          requests for customer service, (ii) addressing fraud, security, or
          technical issues, (iii) protecting the rights, property, and safety of
          Spotlight, its users, and the public, (iv) responding to legal claims
          and processes, or (v) enforcing these Terms, including investigating
          potential violations.
        </p>
      </div>
      <div>
        <h3 className="f3  pt5 pb5 ma0">13. Rights &amp; Ownership</h3>
        <p className="f5">
          Unless otherwise stated, Spotlight and its permitted licensors own all
          intellectual property rights in the Site and its contents. These
          rights include, but are not limited to, ownership of all text,
          graphics, images, logos, copyrighted material, trademarks, word marks,
          service marks, patents, software, and other distinctive brand features
          displayed on the Site or Services, including the compilation of any of
          the foregoing items. Except for the limited rights granted by these
          Terms, neither these Terms nor your use or access to the Site give you
          or any third party any intellectual property rights. We reserve all
          rights not explicitly granted by these Terms, which do not grant any
          implied licenses. All copyrights, trademarks, and other intellectual
          property notices on the Site or Services must be retained on all
          copies thereof. You may not publish, reverse engineer, modify,
          distribute, transmit, sell, create derivative or plagiaristic works
          of, or use or exploit for any commercial reason, whether in whole or
          in part, any of the content on the Site or Services without our
          express prior written consent or the consent of any third-party owners
          of the content. Site content is not for resale under any
          circumstances.
        </p>
      </div>
      <div>
        <h3 className="f3  pt5 pb5 ma0">14. Disclaimers</h3>
        <p className="f5">
          THIS SECTION ONLY APPLIES TO THE MAXIMUM EXTENT PERMITTED BY
          APPLICABLE LAW.{" "}
          <span className="fw-bold">
            THE SITE IS PROVIDED TO YOU ON AN "AS-IS" AND "AS AVAILABLE" BASIS,
            WITHOUT ANY REPRESENTATIONS OR WARRANTIES WITH REGARD TO THE CONTENT
            PROVIDED ON THE SITE. WE, OUR SUBSIDIARIES, OFFICERS, EMPLOYEES,
            CONTRACTORS, AFFILIATES, PARTNERS, SUPPLIERS, AGENTS, AND LICENSORS
            EXPRESSLY DISCLAIM ANY AND ALL WARRANTIES AND CONDITIONS OF ANY
            KIND, WHETHER EXPRESS, IMPLIED, OR STATUTORY, INCLUDING ALL
            WARRANTIES OR CONDITIONS OF MERCHANTABILITY, FITNESS FOR A
            PARTICULAR PURPOSE, TITLE, ACCURACY, OR NON-INFRINGEMENT.
          </span>{" "}
          THIS INCLUDES, WITHOUT LIMITATION TO THE FOREGOING, NO WARRANTY THAT
          THE SITE, ITS CONTENT, OR THE SERVICES WILL BE CONSTANTLY AVAILABLE OR
          AVAILABLE AT ALL, UNINTERRUPTED, USEFUL, TRUE, ACCURATE,
          NON-MISLEADING, TIMELY, RELIABLE, COMPLETE, ERROR-FREE, FREE OF
          OMISSIONS, SECURE, FREE OF VIRUSES OR OTHER HARMFUL CODE, LEGAL, OR
          SAFE. NO INFORMATION, WHETHER ORAL OR WRITTEN, OBTAINED FROM US OR
          THROUGH THE SITE OR SERVICES WILL CREATE ANY WARRANTY NOT EXPRESSLY
          MADE HEREIN.
        </p>
        <p className="f5">
          YOUR USE OF THIS SITE AND THE SERVICES IS AT YOUR SOLE RISK AND WE
          ASSUME NO RESPONSIBILITY FOR HARM TO YOUR COMPUTER SYSTEM, LOSS OF
          DATA, THE DELETION OF INFORMATION YOU TRANSMIT ON THE SITE, OR THE
          DELETION OR FAILURE TO STORE OR TRANSMIT USER CONTENT OR
          PERSONALIZATION SETTINGS THAT MAY RESULT FROM YOUR ACCESS TO OR USE OF
          THE SITE AND SERVICES. YOU HEREBY WAIVE ANY AND ALL CLAIMS AND CAUSES
          OF ACTION THAT MAY CAUSE DAMAGE TO YOUR COMPUTER OR INTERNET ACCESS.
        </p>
        <p className="f5">
          THIS SITE AND ITS CONTENTS ARE PROVIDED FOR INFORMATIONAL PURPOSES
          ONLY. NOTHING ON THIS SITE CONSTITUTES, IS MEANT TO CONSTITUTE, OR MAY
          BE USED AS ADVICE OF ANY KIND, INCLUDING, BUT NOT LIMITED TO, LEGAL,
          OR FINANCIAL (INCLUDING TRADING OR INVESTMENT PURPOSES) ADVICE. WE
          ENCOURAGE YOU TO CONSULT THE APPROPRIATE PROFESSIONAL SHOULD YOU
          REQUIRE LEGAL, FINANCIAL, MEDICAL, OR OTHER PROFESSIONAL ADVICE.
        </p>
        <p className="f5">
          IF APPLICABLE LAW REQUIRES ANY WARRANTIES WITH RESPECT TO THE SITE,
          ALL SUCH WARRANTIES ARE LIMITED IN DURATION TO NINETY (90) DAYS FROM
          THE DATE OF FIRST USE.
        </p>
        <p className="f5">
          SOME JURISDICTIONS DO NOT ALLOW THE EXCLUSION OF IMPLIED WARRANTIES,
          SO THE ABOVE EXCLUSION MAY NOT APPLY TO YOU. SOME JURISDICTIONS DO NOT
          ALLOW LIMITATIONS ON HOW LONG AN IMPLIED WARRANTY LASTS, SO THE ABOVE
          LIMITATION MAY NOT APPLY TO YOU. NOTHING IN THIS SECTION IS INTENDED
          TO LIMIT ANY RIGHTS YOU MAY HAVE WHICH MAY NOT BE LAWFULLY LIMITED.
        </p>
      </div>
      <div>
        <h3 className="f3  pt5 pb5 ma0">15. Limitation of Liability</h3>
        <p className="f5">
          TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, SPOTLIGHT SERVICES
          AND TECHNOLOGIES LIMITED, INCLUDING ITS SUBSIDIARIES, OFFICERS,
          EMPLOYEES, CONTRACTORS, AFFILIATES, PARTNERS, SUPPLIERS, AGENTS, AND
          LICENSORS, MAY NOT BE HELD LIABLE FOR ANY INDIRECT, INCIDENTAL,
          SPECIAL, CONSEQUENTIAL, OR PUNITIVE DAMAGES, OR ANY LOSS OF PROFITS OR
          REVENUES, WHETHER INCURRED DIRECTLY OR INDIRECTLY, OR ANY LOSS OF
          DATA, USE, GOOD-WILL, OR OTHER INTANGIBLE LOSSES, RESULTING FROM (i)
          YOUR ACCESS TO OR USE OF OR INABILITY TO ACCESS OR USE THE SERVICES;
          (ii) THE COST OF PROCURING SUBSTITUTE GOODS OR SERVICES; (iii) ANY
          CONDUCT OR CONTENT OF ANY THIRD PARTY ON THE SERVICES, INCLUDING
          WITHOUT LIMITATION, ANY DEFAMATORY, OFFENSIVE, OR ILLEGAL CONDUCT OF
          OTHER USERS OR THIRD PARTIES; (iv) ANY CONTENT OBTAINED FROM THE
          SERVICES; (v) UNAUTHORIZED ACCESS, USE, OR ALTERATION OF YOUR
          TRANSMISSIONS OR CONTENT; OR (vi) OTHER MATTERS RELATED TO THE SITE OR
          SERVICES. THESE LIMITATIONS APPLY EVEN IF WE HAVE BEEN EXPRESSLY
          ADVISED OF THE POTENTIAL LOSS OR LIABILITY.
        </p>
        <p className="f5">
          NOTWITHSTANDING ANYTHING TO THE CONTRARY CONTAINED HEREIN, IN NO EVENT
          MAY OUR AGGREGATE LIABILITY EXCEED THE AMOUNT YOU PAID US, IF ANY, IN
          THE PAST ONE MONTH FOR THE SERVICES GIVING RISE TO THE CLAIM. THE
          EXISTENCE OF MORE THAN ONE CLAIM WILL NOT ENLARGE THIS LIMIT. YOU
          AGREE THAT OUR AFFILIATES, PARTNERS, SUPPLIERS, AGENTS, AND LICENSORS
          WILL HAVE NO LIABILITY OF ANY KIND ARISING FROM OR RELATING TO YOUR
          USE OF THE SITE OR SERVICES. YOU FURTHER AGREE NOT TO BRING ANY CLAIM
          PERSONALLY AGAINST OUR SUBSIDIARIES, OFFICERS, EMPLOYEES, CONTRACTORS,
          AGENTS, AFFILIATES, PARTNERS, SUPPLIERS, OR LICENSORS.
        </p>
        <p className="f5">
          THE LIMITATIONS IN THIS SECTION APPLY REGARDLESS OF THE THEORY OF
          LIABILITY, WHETHER BASED IN CONTRACT, TORT (INCLUDING NEGLIGENCE),
          WARRANTY, STATUTE, OR OTHERWISE.
        </p>
        <p className="f5">
          NOTE THAT SOME JURISDICTIONS PROHIBIT THE LIMITATION OR EXCLUSION OF
          LIABILITY FOR INCIDENTAL OR CONSEQUENTIAL DAMAGES, AND THEREFORE THE
          LIMITATIONS AND EXCLUSION ABOVE MAY NOT APPLY TO YOU. THE FOREGOING
          DOES NOT AFFECT ANY LIABILITY THAT CANNOT BE EXCLUDED OR LIMITED UNDER
          APPLICABLE LAW.
        </p>
      </div>
      <div>
        <h3 className="f3  pt5 pb5 ma0">16. Indemnity</h3>
        <p className="f5">
          You agree to indemnify, defend, and hold harmless Spotlight and its
          subsidiaries, officers, employees, contractors, agents, affiliates,
          partners, suppliers, and licensors, including, but not limited to,
          costs and attorney's fees, from any claim or disputes by a third party
          arising out of your use of the Site, violation of these Terms,
          violation of applicable law, or your posting, modifying, or otherwise
          transmitting content through the Site or Services. We reserve the
          right, at your expense, to assume exclusive control over the defense
          of any claim or dispute for which you must indemnify us. You agree to
          cooperate fully with us in defending such claims or disputes, and you
          agree not to settle any such claims or disputes without our prior
          written consent. We will make a reasonable effort to provide you with
          notice of any such claim or dispute once we receive notice.
        </p>
      </div>
      <div>
        <h3 className="f3  pt5 pb5 ma0">17. Termination</h3>
        <p className="f5">
          We may suspend or terminate your access to the Site at any time and
          for any reason or no reason at all, with or without notice, at our
          sole discretion. This may result in deletion of information associated
          with your account. You may also terminate your account by deactivating
          it or by submitting a termination request and discontinuing your use
          of the Services. Your account may be deactivated if it experiences a
          prolonged period of inactivity. Where applicable, all rights and
          responsibilities of the parties under these Terms will survive the
          termination of this agreement, including, without limitation,
          intellectual property ownership, warranties, disclaimers, and
          limitations of liability.
        </p>
      </div>
      <div>
        <h3 className="f3  pt5 pb5 ma0">18. Modification of these Terms</h3>
        <p className="f5">
          We may modify or update these Terms from time to time at our sole
          discretion. All updates will be effective from the time and date that
          they are posted. We recommend checking this page regularly for any
          updates. Your continued use of the Site and Services signifies your
          acceptance of the updates that occur. We may send you notice of
          updates to these Terms, including, but not limited to, by email,
          posting on the Site, or other reasonable means.
        </p>
      </div>
      <div>
        <h3 className="f3  pt5 pb5 ma0">19. Violations of these Terms</h3>
        <p className="f5">
          Should you breach these Terms, we may take any and all actions we deem
          appropriate in our sole discretion under the circumstances, including,
          but not limited to, suspending, blocking, or terminating your access
          to the Site and Services and your account.
        </p>
      </div>
      <div>
        <h3 className="f3  pt5 pb5 ma0">20. General Terms</h3>
        <p className="f5">
          <ol style={{ listStyleType: "lower-alpha", padding: "20px" }}>
            <li>
              <p className="" style={{ textDecoration: "underline" }}>
                Entire Agreement:
              </p>{" "}
              <p>
                These Terms and our Privacy Policy represent the entire and
                exclusive agreement between you and Spotlight regarding your use
                of the Site and Services, superseding and replacing all previous
                agreements.
              </p>
              <p>
                YOU MAY ALSO BE SPECIFICALLY SUBJECT TO SEPARATE TERMS AND
                CONDITIONS UNDER SEPARATE WRITTEN AGREEMENTS REGARDING THE
                SERVICES WE PROVIDE, PARTNER OR AFFILIATE SERVICES, USE OF
                THIRD-PARTY RESOURCES, OR ANY PURCHASES YOU MAY MAKE THROUGH THE
                SITE. THESE SEPARATE TERMS AND CONDITIONS SHALL BE BINDING AND
                BE READ IN CONJUNCTION AND HARMONY WITH THESE TERMS, AND ARE
                INCORPORATED HEREIN BY REFERENCE. IN THE EVENT THERE IS A
                DISCREPANCY BETWEEN THESE TERMS AND THE TERMS OF SUCH SPECIFIC
                AGREEMENTS, THE LATTER WILL PREVAIL IN ALL CASES.
              </p>
            </li>
            <li>
              <p className="" style={{ textDecoration: "underline" }}>
                Waiver and Severability:
              </p>{" "}
              <p>
                Our failure to enforce any right or provision of these Terms
                will not operate as a waiver of such right or provision. If any
                provision of these Terms or the application thereof is held to
                be invalid or unenforceable for any reason and to any extent,
                that provision will be considered removed from these Terms;
                however, the remaining provisions will continue to be valid and
                enforceable according to the intentions of the Parties and to
                the maximum extent permitted by law. If it is held that any
                provision of these Terms is invalid or unenforceable, but that
                by limiting such provision it would become valid and
                enforceable, then such provision will be deemed to be written,
                construed, and enforced as so limited.
              </p>
            </li>
            <li>
              <p className="" style={{ textDecoration: "underline" }}>
                Assignment:
              </p>{" "}
              <p>
                Your rights and obligations under these Terms, including any
                accounts, profiles, or personalization settings you may have,
                may not be assigned, subcontracted, delegated, or otherwise
                transferred by you without our prior written consent, and any
                attempt to do so will be null and void. We may freely assign
                these Terms and our rights and obligations hereunder without
                notice to you, and these Terms will continue to be binding on
                assignees.
              </p>
            </li>
            <li>
              <p className="" style={{ textDecoration: "underline" }}>
                Cumulative rights:
              </p>{" "}
              <p>
                The rights of all parties under these Terms are cumulative and
                will not be construed as exclusive of each other unless
                otherwise required by law.
              </p>
            </li>
            <li>
              <p className="" style={{ textDecoration: "underline" }}>
                Law &amp; Jurisdiction:
              </p>{" "}
              <p>
                These terms shall be governed by, and will be construed under,
                the laws of New Jersey, United States of America, without regard
                to or application of its conflict of law principles or your
                state or country of residence. All claims, disputes, and legal
                proceedings related to or arising out of these Terms or your use
                of the Site or Services will be brought exclusively in the
                courts located in New Jersey, United States of America and you
                hereby consent to and waive any objection of inconvenient forum
                as to such jurisdiction.
              </p>
            </li>
          </ol>
        </p>
      </div>
      <div>
        <h3 className="f3  pt5 pb5 ma0">21. Electronic Communications</h3>
        <p className="f5">
          Electronic communications occur whenever you use the Site or Services,
          you send us emails, we send you emails, and we post notices on the
          Site. You consent to receive such electronic communications and agree
          that the electronic communications, including, without limitation, all
          notices, terms, disclosures, and agreements, has the same legal effect
          and satisfy any legal requirement that such communications would
          satisfy if provided to you in a written hardcopy.
        </p>
      </div>
      <div>
        <h3 className="f3  pt5 pb5 ma0">22. Feedback &amp; Complaints</h3>
        <p className="f5">
          You hereby assign to us all rights in any questions, comments,
          suggestions, feedback or complaints you provide us concerning the Site
          or Services and agree that we have the right to use and fully exploit
          all such feedback or complaints in any manner we wish, commercial or
          otherwise, unless indicated otherwise for a particular communication.
          We will treat all such feedback or complaints as non-confidential and
          non-proprietary, unless explicitly agreed to otherwise in writing. Do
          not provide us with any feedback or complaints that you consider
          confidential or proprietary. In addition, Spotlight is free to use any
          ideas, concepts, know-how or techniques contained in such
          communications for any purpose including, but not limited to,
          developing and marketing products using such information without
          compensation to you.
        </p>
      </div>
      <div>
        <h3 className="f3  pt5 pb5 ma0">23. Feedback &amp; Complaints</h3>
        <p className="f5">
          Please report any violations of these Terms to us. If you require any
          more information or have any questions about these Terms, you may
          contact us at the following address:
        </p>
        <address className="f5">
          Spotlight Services &amp; Technologies Incorporated
          <br />
          362, Orenda Circle <br />
          Westfield, New Jersey 07090 <br />
          Phone: +1 (908) 249 3363 <br />
          Email:{" "}
          <a href="mailto:takecontrol@spotlightandcompany.com">
            takecontrol@spotlightandcompany.com
          </a>
        </address>
      </div>
    </div>
  );
};

export default TOUGlobalComponent;
